import React from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";


   
 

const ProductList = () => {


const data = useStaticQuery(graphql`
    {
      allWpProduct{ 
        nodes {
            additionalInformation {
              bestFor {
                name
              }
            }
            image {
              mediaItemUrl
              title
              uri
            }
            description
            featured
            link
            sku
            status
            ... on WpSimpleProduct {
              id
              name
              price
            }
          }
      }
    }
  `)
 // return <pre>{JSON.stringify(data, null, 4)}</pre>
 console.log(data)
 return(
    
    <Layout>
      <div class="taxonomy-page">
 <div class="container-fluid cs-new-arrivals products-new-arrivals">
            <div class="d-flex flex-wrap justify-content-between align-items-end cs-header">
                <div class="cs-head all-products-title cs-content">
                    <h2><span>Tough love</span> is the ultimate balancing <span>act</span> for your <span>skin</span></h2>
                </div>
                <div class="cs-head all-products-title cs-content">
	                <div class="products-filter-container"> 
	                    <div class="product-filter-skin"> 
	                        <p>Concerns</p>
	                        <select class="custom-dropdown" id="concernselect" name="concern">
	                            <option value="all">All</option>
	                            	                                    <option value="loss-of-elasticity-firmness" >Loss of Elasticity &amp; Firmness</option>
	                            	                                    <option value="lines-wrinkles" >Lines &amp; Wrinkles</option>
	                            	                                    <option value="first-signs-of-aging" >First Signs of Aging</option>
	                            	                                    <option value="dark-circles-puffiness-concern" >Dark Circles &amp; Puffiness</option>
	                            	                                    <option value="dryness-dehydration" >Dryness &amp; Dehydration</option>
	                            	                                    <option value="acne-blemishes" >Acne &amp; Blemishes</option>
	                            	                                    <option value="oily-skin" >Oily Skin</option>
	                            	                                    <option value="redness-sensitivity" >Redness &amp; Sensitivity</option>
	                            	                                    <option value="dull-uneven-skin-tone" >Dull, Uneven skin tone</option>
	                            
	                        </select>
	                    </div>

	                    <div class="product-filter-product-type" > 
	                        <p>Categories </p>
	                        <select class="custom-dropdown" id="categoryselect" name="category">
	                            <option  value="all">All</option>
	                           
	                            	                                    <option value="bestseller" selected>Best Sellers</option>
	                            	                                    <option value="serums" >Serums</option>
	                            	                                    <option value="moisturizers" >Moisturizers</option>
	                            	                                    <option value="eye-treatments" >Eye Treatments</option>
	                            	                                    <option value="cleansers" >Cleanser</option>
	                            	                                    <option value="exfoliators" >Exfoliators</option>
	                            	                                    <option value="sunscreen" >Sunscreen</option>
	                            
	                        </select>
	                    </div>

	                </div>
            	</div>

            </div>
        </div>
    </div>
        <div class="products-lists-new-cntnr">
        <div class="home-new-product-card-conatiner product-page-lists">
                    <div class="new-product-col-outr  allcatproducts allconcernproducts" id="product-111">
                    <div class="new-product-col new-badge">
                        <div class="new-badge-box">Best Sellers</div>
                        
                        <div class="new-product-image">
                            <a href="https://www.carrotstick.com/product/the-repair-serum/" class="img">
                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/11/RepairSerum_@6x.png" class="img-fluid"/>
                            </a>
                        </div>
                        <div class="new-product-title">
                            <a href="https://www.carrotstick.com/product/the-repair-serum/"><h4>The Repair Serum</h4></a>
                            <div class="new-product-price"><span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">&#36;</span>80</bdi></span></div>
                        </div>
                        <div class="new-product-rating"> 
                            <div class="review-stars-cntnr">
                                 <div id="category-snippet-111"></div>
                            </div> 

                        </div>
                        <div class="new-poduct-features">
                            <ul>
                                <li>Visibly Lifts, Tightens & Firms</li><li>Encourages Elasticity</li><li>Ageless Glow</li>                            </ul>
                        </div>
                    </div>
                </div>
                         <div class="new-product-col-outr  allcatproducts allconcernproducts" id="product-20">
                    <div class="new-product-col new-badge">
                        <div class="new-badge-box">Best Sellers</div>
                        
                        <div class="new-product-image">
                            <a href="https://www.carrotstick.com/product/the-defense-serum/" class="img">
                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2020/11/DefenceSerum_@6x.png" class="img-fluid"/>
                            </a>
                        </div>
                        <div class="new-product-title">
                            <a href="https://www.carrotstick.com/product/the-defense-serum/"><h4>The Defense Serum</h4></a>
                            <div class="new-product-price"><span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">&#36;</span>80</bdi></span></div>
                        </div>
                        <div class="new-product-rating"> 
                            <div class="review-stars-cntnr">
                                 <div id="category-snippet-20"></div>
                            </div> 

                        </div>
                        <div class="new-poduct-features">
                            <ul>
                                <li>Antioxidant Superpower</li><li>Clear & Radiant </li><li>Visibly Brightens Skin</li>                            </ul>
                        </div>
                    </div>
                </div>
                           <div class="new-product-col-outr  allcatproducts allconcernproducts" id="product-22">
                    <div class="new-product-col new-badge">
                        <div class="new-badge-box">Best Sellers</div>
                        
                        <div class="new-product-image">
                            <a href="https://www.carrotstick.com/product/the-moisturizer/" class="img">
                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/11/Moisturizer_@6x.png" class="img-fluid"/>
                            </a>
                        </div>
                        <div class="new-product-title">
                            <a href="https://www.carrotstick.com/product/the-moisturizer/"><h4>The Moisturizer</h4></a>
                            <div class="new-product-price"><span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">&#36;</span>60</bdi></span></div>
                        </div>
                        <div class="new-product-rating"> 
                            <div class="review-stars-cntnr">
                                 <div id="category-snippet-22"></div>
                            </div> 

                        </div>
                        <div class="new-poduct-features">
                            <ul>
                                <li>Lightweight Formula</li><li>Hydrating Powerhouse</li><li>Smooth & Radiant</li>                            </ul>
                        </div>
                    </div>
                </div>
                          <div class="new-product-col-outr  allcatproducts allconcernproducts" id="product-12865">
                    <div class="new-product-col new-badge">
                        <div class="new-badge-box">Best Sellers</div>
                        
                        <div class="new-product-image">
                            <a href="https://www.carrotstick.com/product/the-repair-cream-for-dry-skin/" class="img">
                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Repair-Cream.png" class="img-fluid"/>
                            </a>
                        </div>
                        <div class="new-product-title">
                            <a href="https://www.carrotstick.com/product/the-repair-cream-for-dry-skin/"><h4>The Repair Cream. For Dry Skin.</h4></a>
                            <div class="new-product-price"><span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">&#36;</span>60</bdi></span></div>
                        </div>
                        <div class="new-product-rating"> 
                            <div class="review-stars-cntnr">
                                 <div id="category-snippet-12865"></div>
                            </div> 

                        </div>
                        <div class="new-poduct-features">
                            <ul>
                                <li>Helps protect skin microbiome</li><li>Locks in moisture</li><li> Visibly improves signs of dryness</li>                            </ul>
                        </div>
                    </div></div>
                         <div class="new-product-col-outr  allcatproducts allconcernproducts" id="product-14">
                    <div class="new-product-col new-badge">
                        <div class="new-badge-box">Best Sellers</div>
                        
                        <div class="new-product-image">
                            <a href="https://www.carrotstick.com/product/the-eye-cream/" class="img">
                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/11/EyeCream_@6x.png" class="img-fluid"/>
                            </a>
                        </div>
                        <div class="new-product-title">
                            <a href="https://www.carrotstick.com/product/the-eye-cream/"><h4>The Eye Cream</h4></a>
                            <div class="new-product-price"><span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">&#36;</span>60</bdi></span></div>
                        </div>
                        <div class="new-product-rating"> 
                            <div class="review-stars-cntnr">
                                 <div id="category-snippet-14"></div>
                            </div> 

                        </div>
                        <div class="new-poduct-features">
                            <ul>
                                <li>Revolutionary Treatment</li><li>Visibly Reduces Bags & Circles</li><li>Restores Eye Vitality</li>                            </ul>
                        </div>
                    </div>
                </div>
                           <div class="new-product-col-outr  allcatproducts allconcernproducts" id="product-12343">
                    <div class="new-product-col new-badge">
                        <div class="new-badge-box">Best Sellers</div>
                        
                        <div class="new-product-image">
                            <a href="https://www.carrotstick.com/product/the-mineral-sunscreen-spf-50-for-face/" class="img">
                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Mineral-Sunscreen-SPF-50-1-1.png" class="img-fluid"/>
                            </a>
                        </div>
                        <div class="new-product-title">
                            <a href="https://www.carrotstick.com/product/the-mineral-sunscreen-spf-50-for-face/"><h4>The Mineral Sunscreen SPF 50</h4></a>
                            <div class="new-product-price"><span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">&#36;</span>60</bdi></span></div>
                        </div>
                        <div class="new-product-rating"> 
                            <div class="review-stars-cntnr">
                                 <div id="category-snippet-12343"></div>
                            </div> 

                        </div>
                        <div class="new-poduct-features">
                            <ul>
                                <li>Shields against UVA and UVB rays</li><li>Nourishing, non-greasy</li><li>Water Resistant</li>                            </ul>
                        </div>
                    </div>
                </div>
                           <div class="new-product-col-outr  allcatproducts allconcernproducts" id="product-12662">
                    <div class="new-product-col new-badge">
                        <div class="new-badge-box">Best Sellers</div>
                        
                        <div class="new-product-image">
                            <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="img">
                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Gentle-Hydrating-Cleanser.png" class="img-fluid"/>
                            </a>
                        </div>
                        <div class="new-product-title">
                            <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/"><h4>The Gentle Hydrating Cleanser</h4></a>
                            <div class="new-product-price"><span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">&#36;</span>40</bdi></span></div>
                        </div>
                        <div class="new-product-rating"> 
                            <div class="review-stars-cntnr">
                                 <div id="category-snippet-12662"></div>
                            </div> 

                        </div>
                        <div class="new-poduct-features">
                            <ul>
                                <li>Oil Free to Natural hydrators. Won’t strip precious oils</li><li>Nourishing</li><li>Antioxidant-Packed</li>                            </ul>
                        </div>
                    </div>
                </div>
  
        </div></div>
 </Layout>
 )
  
}

export default ProductList
